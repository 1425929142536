.navbar-raintree {
    font-family: @font-stack-sans-serif;
    position: relative;
    margin-bottom: 30px;
}
.navbar-raintree .navbar-brand-logo {
    max-height: 55px;
}
.navbar-raintree .navbar-nav {
    margin-top: 25px;
    background: #fff;
    z-index: 100;
}
.navbar-raintree .navbar-nav li {
	position: initial;
}
.navbar-raintree .navbar-nav li a {
    color: @header-bar-color;
    font-weight: 600;
    padding: 10px 15px;
}
.navbar-raintree .navbar-form {
    margin-top: 25px;
    padding: 3px;
    margin-left: 20px;
}
.navbar-raintree .navbar-form input {
    font-weight: bold;
    font-size: 8pt;
    padding-left: 40px;
    min-width: 220px;
    box-shadow: none;
}
.navbar-raintree .navbar-form .search {
    position: relative;
}
.navbar-raintree .navbar-form .search-icon {
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    border: 0px;
    z-index: 10;
    padding-top: 8px;
    padding-left: 14px;
}
.navbar-raintree .navbar-form .search-advanced {
    font-size: 10pt;
    position: absolute;
    background: none;
    right: 0;
    top: 0;
    bottom: 0;
    border: 0px;
    z-index: 10;
    margin: 5px 0;
    padding: 2px 14px 2px 14px;
    color: @brand-color;
    border-left: 1px solid #ccc;
    cursor: pointer;
}
.navbar-raintree .navbar-toggle,
.navbar-raintree .search-toggle {
    margin-top: 20px;
    margin-right: 0;
}
.search-toggle {
    background: none;
    border: 0px;
    padding: 5px;
}
.navbar-raintree .navbar-toggle span.icon-bar {
    background: @brand-color;
}
.navbar-raintree .mobile-search-button {
    cursor: pointer;
    right: 0;
    top: 0;
    position: absolute;
    margin-right: 80px;
    font-size: 19px;
    color: @brand-color;
}
.navbar-raintree .container-fluid {
	padding: 0;
}
@media (min-width: 768px) and (max-width: 1200px) {
    .navbar-raintree .mobile-search-button {
        margin-right: 100px
    }
}
@media (min-width: 1200px) {
    .navbar-raintree .navbar-form #mobile-search {
        display: none;
    }
    .navbar-raintree .mobile-search-button {
        display: none;
    }
    .nav-search {
    	display: none;
    }
    .navbar-raintree .navbar-right {
    	padding-right: 0;
    }

}
@media (max-width: 1200px) {
	.navbar-raintree {
		min-height: 100px;
		margin-bottom: 10px;
	}
	.navbar-raintree .navbar-nav {
		margin-top: 0;
	}
    .navbar-raintree .navbar-nav li {
        text-align: left;
    }
    .navbar-raintree .navbar-nav li a:hover {
        background: #FAFAFA;
    }
    .navbar-raintree .navbar-form,
    .navbar-raintree .navbar-form input {
        margin: 0px;
    }
    .navbar-raintree .navbar-form {
        text-align: left;
    }
    .navbar-raintree .navbar-header {
        float: none;
    }
    .navbar-raintree .navbar-left,
    .navbar-right {
        float: none !important;
    }
    .navbar-raintree .navbar-toggle {
        display: block;
    }
    .navbar-raintree .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-raintree .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-raintree .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-raintree .collapsing {
	    width: 320px;
	}
	.navbar-collapse {
		padding: 0;
	}
    .navbar-raintree .in, .navbar-raintree .collapsing {
    	z-index: 123;
    	position: absolute;
    	right: 10px;
        float: left !important;
        margin-top: 0px;
        max-width: 320px;
        border: 1px solid #ddd;
        background: #fff;
    }
    .navbar-raintree .navbar-nav>li {
        float: none;
    }
    .navbar-raintree .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-raintree .collapse.in {
        display: block !important;
    }
    .navbar-raintree .navbar-form #desktop-search {
        display: none;
    }
    .nav-search {
    	background: #fafafa;
    }
    #mobile-search {
    	margin-top: 0px;
    }
    .nav-search .form-group, .nav-search .form-control {
    	width: 100%;
    }
    .navbar-raintree .navbar-nav li {
		position: relative;
	}
}
@media (max-width: 768px) {
	.navbar-nav {
		margin: 0;
	}
	.navbar-raintree .navbar-toggle {
    	margin-right: 15px !important; 
    }
}
@media (max-width: 400px) {
	.navbar-brand img {
		margin-top: 8px;
		width: 120px;
		margin-left: 0px;
	}
	.navbar-raintree {
		margin-bottom: 0;
	}
}