.btn-accent-1:focus,
.btn-accent-2:focus,
.btn-accent-3:focus,
.btn-accent-4:focus {
    color: #fff;
}
.btn-accent-1 {
    background: @color-accent-1;
    color: #fff;
}
.btn-accent-1:hover,
.btn-accent-1:focus {
    background: @color-accent-1-light;
    color: #fff;
}
.btn-accent-2 {
    background: @color-accent-2;
    color: #fff;
}
.btn-accent-2:hover,
.btn-accent-2:focus {
    background: @color-accent-2-light;
    color: #fff;
}
.btn-accent-3 {
    background: @color-accent-3;
    color: #fff;
}
.btn-accent-3:hover,
.btn-accent-3:focus {
    background: @color-accent-3-light;
    color: #fff;
}
.btn-accent-4 {
    background: @color-accent-4;
    color: #fff;
}
.btn-accent-4:hover,
.btn-accent-4:focus {
    background: @color-accent-4-light;
    color: #fff;
}
a.btn-accent-4:link,
a.btn-accent-3:link,
a.btn-accent-2:link,
a.btn-accent-1:link,
a.btn-accent-4:active,
a.btn-accent-3:active,
a.btn-accent-2:active,
a.btn-accent-1:active,
a.btn-accent-4:visited,
a.btn-accent-3:visited,
a.btn-accent-2:visited,
a.btn-accent-1:visited,
a.btn-accent-4:hover,
a.btn-accent-3:hover,
a.btn-accent-2:hover,
a.btn-accent-1:hover {
	color: #fff;
}
.btn-center {
	display: block;
	margin: 40px auto;
	max-width: 50%;
}