@card-box-shadow-color: #ddd;
.products {
    position: relative;
    overflow: hidden;
    height: 295px;
    max-width: 1100px;
    display: block;
    margin: 0 auto;
}
.products-action {
    margin-top: 50px;
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}
.product-slider-page-band {
	background: #FAF9F1;
	margin-top: 20px;
	padding-top: 20px;
	padding-bottom: 0;
	margin-bottom: 40px;
}
.products-mask {
    position: absolute;
    top: 0;
    bottom: -10px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    z-index: 99;
    border: 15px solid #FAF9F1;
    border-bottom: 0;
    pointer-events: none;
}
.products::before {
    height: 100%;
    background: red;
    width: 20px;
}
.product-line {
    position: relative;
    white-space: nowrap;
    overflow-x: scroll;
    padding: 0;
    -webkit-overflow-scrolling: touch;
}
.product {
    position: relative;
    display: inline-block;
    min-height: 250px;
    width: 150px;
    margin: 20px 30px;
}
.product:hover .product-actions {
    height: 65px;
    -webkit-transition: height 0.3s, opacity 0.3s ease-in;
    transition: height 0.3s, opacity 0.3s ease-in;
    visibility: visible;
    opacity: 1;
}
.product-actions {
    position: absolute;
    bottom: 90px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    height: 0;
    visibility: hidden;
    opacity: 0;
}
.product-actions button {
    width: 90%;
    display: block;
    margin: 5px auto 5px auto;
    font-size: 12pt;
}
.product img {
    display: block;
    margin: 0 auto 20px auto;
    -webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
    box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
    width: 100%;
}
.product:hover img {
    -webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color-dark;
    box-shadow: 0 10px 30px 0 @generic-box-shadow-color-dark;
}
.product .product-name {
    font-size: 12pt;
    margin: 10px 0;
    font-weight: 600;
}
.product a.product-name:link,
.product a.product-name:active,
.product a.product-name,
.product a.product-name:visited 
{
    color: #333;
}
.product a.product-name:hover {
    color: @brand-color;
}
.product .product-by {
    font-size: 10pt;
    color: #777;
}
.product .product-price {
    font-size: 10pt;
    margin-top: 10px;
    font-weight: 600;
}
.product:first-child {
    margin-left: -40px;
}
.product:last-child {
    margin-right: -40px;
}
.products-controls {
	display: block;
	margin: 0 auto;
	position: relative;
	max-width: 1200px;
}
.products-controls .control {
    bottom: 150px;
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    cursor: pointer;
    z-index: 100;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.products:hover .control {
    display: block;
}
.products-controls .control-next {
    right: 0px;
}
.products-controls .control-previous {
    left: 0px;
}
.products-controls .control-next i,
.products-controls .control-previous i {
    line-height: 48px;
    padding: 0 13px;
    margin: 0;
    font-size: 18pt;
}
















.adding-to-cart {
	height: 40px;
	width: 100%;
	z-index: 99;
	position: absolute;
	top: 60px;
}
.adding-to-cart .action-badge {
	background: #53B4AE;
	color: #fff;
	display: block;
	margin: 0 auto;
	width: 50px;
	height: 50px;
	font-size: 16pt;
	text-align: center;
	line-height: 45px;
	border-radius: 25px;
	border: 3px solid #f8f8f8;
	padding-left: -1px;
	-webkit-box-shadow: 0 5px 5px 1px rgba(33,33,33,0.1);
    box-shadow: 0 5px 10px 1px rgba(33,33,33,0.1);
    -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.3s; /* Firefox < 16 */
    -ms-animation: fadein 0.3s; /* Internet Explorer */
    -o-animation: fadein 0.3s; /* Opera < 12.1 */
    animation: fadein 0.3s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}