.content-filter {
    position: relative;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #FAFAFA;
    border-color: @alert-info-color-dark;
    display: table;
    width: 100%;
}
.content-filter-heading {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    font-weight: 600;
}
.content-filter select {
	margin: 0 20px;
	min-width: 150px;
}
.content-filter-right {
	float: right;
}
.content-filter-content {
    width: 100%;
}
.content-filter .dropdown-menu .checkbox {
    margin-left: 20px;
}
.content-filter button {
	border-radius: 5px;
	border: 1px solid #ddd;
	background: #fff;
	height: 35px;
	width: 35px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, .075);
}
.content-filter button:hover {
	color: #777;
}
@media (max-width: 768px) {
    .content-filter {
        position: relative;
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
    }
    .content-filter .dropdown {
        margin-left: 20px;
        display: inline-block;
        position: initial;
    }
    .content-filter .dropdown-menu {
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 20px;
    }
}
@media (min-width: 768px) {
    .content-filter stong {
        vertical-align: middle;
    }
    .content-filter .dropdown {
        display: inline-block;
    }
    .content-filter .dropdown-label {
        display: none;
    }
    .content-filter .dropdown-menu {
        position: relative;
        display: inline-block;
        box-shadow: none;
        border: 0;
        background: none;
    }
    .content-filter .dropdown-menu .form-inline {
        display: inline-block;
    }
}
@media (max-width: 500px) {
    .content-filter-heading {
        display: block;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .content-filter .dropdown {
        min-height: 30px;
    }
    .content-filter select {
    	margin-left: 0;
    }
}
@media (min-width: 500px) and (max-width: 768px) {
	.content-filter .form-group {
		display: inline-block;
	}
	.content-filter select {
		width: 200px;
		display: inline-block;
	}
}