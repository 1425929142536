.cart-table {
	width: 100%;
}
.cart-table>tbody>tr>th:first-child {
	padding-left: 10px;
}
.cart-table>tbody>tr>th {
	padding: 10px 0;
	border-top: 0;
}
.cart-table>tbody>tr>th:nth-child(3) {
	padding: 10px;
}
.cart-table td {
	padding: 20px;
	vertical-align: middle;
}
.cart-table .product-name {
	width: 70%;
}
.cart-table a.btn .glyphicon {
	padding-top: 2px;
}
.cart-table select.form-control {
	min-width: 60px;
}
.cart-table td:last-child {
    width:1%;
    white-space:nowrap;
}
.cart-table .product-row:hover {
	background: #f9f9f9;
}
.price-table {
	float: right;
	max-width: 200px;
}
.price-table th {
	text-align: right;
}
.checkout-button {
	float: right;
	width: 200px;
	font-size: 18pt;
	padding: 10px;
	margin-bottom: 40px;
}
.checkout-button span.glyphicon {
	margin-right: 10px;
}
.mini-cart {
	border: 1px solid #ddd;
	padding: 10px;
	margin-bottom: 40px;
	border-radius: 5px;
}
.mini-cart h3 {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
}