.consultant-listing {
	border: 1px solid #ddd;
	border-top: 0;
	margin-bottom: 0px;
	padding: 20px;
}
.consultant:last-child {
	border-bottom: 0;
}
.consultant {
    min-height: 200px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 0px;
}
.consultant img {
    float: left;
    margin: 0 20px 20px 0;
    width: 200px;
}
.consultant .consultant-contact {
    margin-top: 40px;
}
.consultant .consultant-contact td {
    padding: 10px;
}
.consultant .consultant-contact i {
    font-size: 24px;
}
@media (max-width: 768px) {
    .consultant img {
        width: 100px;
    }
    .consultant .consultant-contact {
        margin-top: 20px;
    }
    .consultant .consultant-contact td {
        padding: 5px;
    }
}
@media (max-width: 410px) {
    .consultant .consultant-contact {
        margin-left: 20px;
        margin-bottom: 20px;
    }
}