@card-border-radius: 5px;
.card-holder {
	margin-top: 0px;
	position: relative;
	padding-top: 30px;
	margin-bottom: 40px;
}
.card-holder a:link,
.card-holder a:hover,
.card-holder a:visited,
.card-holder a:active {
	color: #000;
}
.card-holder a.btn:link,
.card-holder a.btn:hover,
.card-holder a.btn:visited,
.card-holder a.btn:active {
	color: #fff;
}
.card-background {
	position: absolute;
	min-height: 450px;
	width: 100%;
	top:0;
	left:0px;
	background: #FAF9F1;
	padding-bottom: 40px;
	margin-bottom: 40px;
}
.card {
	margin-top: 40px;
    min-height: 500px;
    border-radius: @card-border-radius;
    -webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
    box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
    margin-bottom: 40px;
    -max-width: 450px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
}
.card:hover {
    -webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color-dark;
    box-shadow: 0 10px 30px 0 @generic-box-shadow-color-dark;
    cursor: pointer;
}
.card img {
    width: 100%;
    border-radius: @card-border-radius @card-border-radius 0 0;
}
.card-caption {
    padding: 20px 40px;
}
.card-caption p {
    line-height: 2em;
}
.card-caption h3 {
    font-size: 18pt;
    margin-bottom: 30px;
}
.card-actions {
	text-align: center;
}