.free-download-image {
    position: relative;
    background-color: #eee;
    min-height: 200px;
    background-image: url('/assets/img/free-download.png');
    background-size: cover;
    cursor: pointer;
}
.free-download:hover .free-download-image {
    -webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
    box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
}
.free-download h4 {
    background-color: #fff;
    font-size: 11pt;
    font-weight: 600;
}
.free-download-info {
    padding: 10px 0;
}
.free-download-action-button {
    padding-right: 20px;
    float: right;
}
.free-download-action:hover {
    color: @color-accent-4;
}
.row .col-md-4:first-child .free-download-image {
    background: #FFF1DF;
}
.row .col-md-4:nth-child(2) .free-download-image {
    background: #DFF5FF;
}
.row .col-md-4:last-child .free-download-image {
    background: #FFDFDF;
}
.free-download-header h1 {
	text-align: center;
	font-size: 18pt;
	font-weight: 600;
	margin-bottom: 40px;
}
.free-download-image span {
    display: block;
    position: absolute;
    top: 150px;
    right: 20px;
    font-size: 24pt;
    color: #fff;
}