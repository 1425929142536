.product-page-image {
	margin-top: 50px;
	background: #fff;
	max-height: 450px;
	padding: 40px;
}
.product-page-image img {
	display: block;
	margin: auto;
	width: auto;
	height: 300px;
	-webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
    box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
}
.product-page-series {
	margin-top: 20px;
	padding: 20px 20px;
	background: #F6F6F6;
	border: 1px solid #D1D4D6;
	border-radius: 5px;
}
.product-page-titles {
	margin-top: 20px;
	padding: 10px 20px;
	background: #F6F6F6;
	border: 1px solid #D1D4D6;
	border-radius: 5px;
}
.product-page-titles p {
	margin-bottom: 0;
}
.product-page-series .btn, .product-page-series p {
	display: inline-block;
}
.product-page-series .btn {
	float: right;
}
.product-page-series p {
	margin-bottom: 0;
	padding: 5px 10px 5px 0;
}
.product-page-info {
	position: relative;
	min-height: 300px;
	margin-bottom: 100px;
	padding-top: 30px;
	margin-left: 25px;
	margin-right: 50px;
}
.product-page-info h3 {
	margin-top: 30px;
	margin-bottom: -30px;
}
.product-page-info table {
	margin-top: 40px;
	width: 100%;
}
.product-page-info table td {
	padding: 10px 20px;
}
.product-page-info table td:last-child {
	text-align: right;
	padding-right: 0;
}
@media (max-width: 992px) {
	.product-page-info {
		padding-top: 0;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 40px;
	}
	.product-page-info table td {
		padding: 10px 10px;
	}
	.product-page-aside {
		margin: 0 !important;
	}
	.product-page-quick-info {
		padding-top: 0 !important;
	}
	.product-page-info table {
		margin-top: 20px !important;
	}
	.product-page-image {
		min-height: 350px;
		margin-top: 0;
	}
	.product-page-heading button {
		position: relative !important;
	}
	.product-page-info h3 {
		margin-bottom: 0;
	}
}
@media (max-width: 1200px) {
	.product-page-series {
		padding-top: 5px;
		padding-bottom: 5px;
		text-align: center;
	}
	.product-page-series .btn {
		display: block;
		margin: 5px auto 10px auto;
		float: none;
	}
}
.product-page-aside {
	margin-right: 25px;
	margin-left: 50px;
}
.product-page-description {
	margin-top: 20px;
	color: #C0B9A3;
}
.product-page-quick-info {
	margin-top: 20px;
	padding: 10px 0;
}
.product-page-quick-info h2 {
	font-weight: 600;
	font-size: 14pt;
}
.product-page-meta span {
	color: #000;
}
.product-page-meta {
	font-size: 9pt;
	color: #C0B9A3;
}
.product-page-preview {
	margin-top: 10px;
	padding: 10px 20px;
	text-align: center;
	clear: both;
}
.product-page-preview .glyphicon {
	margin-right: 10px;
}
.product-page-heading {
	position: relative;
}
.product-page-heading h2 {
	font-weight: 600;
}
.product-page-heading button {
	position: absolute;
	right: 0;
	top: 0;
}