.breadcrumb-menu {
	min-height: 50px;
	background: @component-background-color;
	border-top: 1px solid #D1D4D6;
	border-bottom: 1px solid #D1D4D6;
	overflow: hidden;
}
.breadcrumb-menu ul {
	padding: 0;
	margin: 0;
}
.breadcrumb-menu ul li:first-child {
	margin-left: 0;
}
.breadcrumb-menu ul li {
	display: inline-block;
	line-height: 50px;
	margin-left: 20px;
	font-weight: bold;
	text-transform: uppercase;
}
.breadcrumb-menu ul li a {
	color: #333;
}
.breadcrumb-menu ul li a:hover {
	color: @brand-color;
}
.breadcrumb-menu ul li:after {
	margin-left: 20px;
	content: "/";
	color: #ccc;
}
.breadcrumb-menu ul li:last-child:after {
	margin-left: 0;
	content: "";
}

@media (max-width: 768px) {
	.breadcrumb-menu {
		display: none;
	}
}
.breadcrumb-spacer {
	min-height: 30px;
}