.section-fluid {
	background: #FAF9F1;
	margin-top: 0;
	padding-top: 40px;
	padding-bottom: 40px;
	margin-bottom: 40px;
}
.section-container {
	margin-bottom: 40px;
}
.section-container img {
	max-width: 100%;
}
.section-fluid {
	padding: 50px;
}
.section-fluid img {
	margin: 0 auto;
	display: block;
}
.section-fluid h1, .section h1 {
	font-weight: 600;
	margin-bottom: 20px;
}
.section-title {
	text-transform: uppercase;
	font-weight: bold;
	color: #aaa;
}
.section-fluid p, .section p, .section li {
	line-height: 2;
}

.content-section h2 {
	margin: 30px 0;
}
.content-section p {
	color: #999;
	font-size: 14pt;
	line-height: 2em;
}
.content-section .btn {
	margin: 50px 0 0 0;
}

.feature-image {
	min-height: 300px;
	background: #F6F6F6;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.section {
	max-width: 800px;
	margin: 80px auto;
}
.side-section {
	display: inline-block;
	min-height: 500px;
}
.side-section {
	padding: 40px 0;
}
.side-section-background {
	padding: 60px;
}
.side-section h1 {
	margin-bottom: 30px;
	font-weight: 600;
}
.side-section p, .side-section ul li {
	line-height: 2.5em;
	font-weight: 500;
	margin: 0;
}
.side-section .btn {
	margin-top: 40px;
	margin-bottom: 20px;
}
.side-image {
	background: #eee;
	min-height: 500px;
	display: block;
	width: 100%;
	height: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.side-image img {
	width: 100%;
}
@media (max-width: 1300px) {
	.side-section {
		padding: 50px 50px;
	}
}
@media (max-width: 992px) {
	.side-image {
		margin-bottom: 40px;
	}
	.side-section, .side-image {
		max-height: auto;
		min-height: 250px;
	}
	.side-section {
		padding: 10px;
		margin-bottom: 40px;
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.section-fluid {
		padding-top: 40px;
		padding: 10px;
	}
	.section-content {
		padding-top: 40px;
	}
}
.large-intro-container {
	min-height: 1180px;
	background: #FFF1E9;
	margin-bottom: 40px;
}
.large-intro-container .page-title {
	font-weight: 600;
	margin-bottom: 30px;
}
.section-container .container .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}
.section-intro-image {
	max-width: 400px;
	max-height: 400px;
}