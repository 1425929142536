.content-listing-container {
	border-top: 0;
	padding: 0 20px 0 20px;
}
.content-listing {
    min-height: 140px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    position: relative;
}
.content-listing:last-child {
	border-bottom: 0;
	margin-bottom: 20px;
}
.content-listing h2 {
    margin-right: 80px;
}
.content-listing h2 a {
    color: #333;
}
.content-listing h2 a:hover {
    color: @brand-color;
}
.content-listing img {
    float: left;
    margin: 0 20px 0 0;
    width: 200px;
    height: 200px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.content-listing-tags {
    position: absolute;
    top: 0;
    right: 0;
}
@media (max-width: 992px) {
	.content-listing img {
        width: 100%;
        height: 200px;
        margin: 0 auto;
        display: block;
        float: none;
    }
    .content-listing-tags {
        position: relative;
        margin-bottom: 15px;
    }
}
@media (max-width: 768px) {
    .content-listing img {
        height: 300px;
    }

}
@media (max-width: 600px) {
    .content-listing img {
        height: 200px;
    }
}
.content-intro {
    margin-top: 40px;
    margin-bottom: 40px;
}