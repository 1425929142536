.checkout-form {
	margin-top: 30px;
}
.checkout-form .radio {
	margin-bottom: 20px;
	margin-left: 20px;
}
.checkout-progress-labels li {
	list-style-type: none;
	display: inline-block;
	margin: 0;
	padding: 0;
	font-size: 10pt;
	text-align: center;
}
.checkout-progress-labels a:first-child li, .checkout-progress-labels>li:first-child {
	text-align: left;
}
.checkout-progress-labels a:last-child li, .checkout-progress-labels>li:last-child  {
	text-align: right;
}
.checkout-progress-labels {
	margin: 0;
	padding: 0;
	font-size: 0;
}
.checkout-progress .progress {
	margin-bottom: 10px;
}

/* Different pages are going to need to be spaced differently to get the button to line up... */
.checkout-form-spacer-1 {
	min-height: 68px;
	clear: both;
}