.progress-bar-accent-1 {
    background-color: @color-accent-1;
}
.progress-bar-accent-1-active {
    background-color: @color-accent-1-light;
}

.progress-bar-accent-2 {
    background-color: @color-accent-2;
}
.progress-bar-accent-2-active {
    background-color: @color-accent-2-light;
}

.progress-bar-accent-3 {
    background-color: @color-accent-3;
}
.progress-bar-accent-3-active {
    background-color: @color-accent-3-light;
}

.progress-bar-accent-4 {
    background-color: @color-accent-4;
}
.progress-bar-accent-4-active {
    background-color: @color-accent-4-light;
}