.radio-label {
    font-weight: bold;
    font-size: 11pt;
    margin-right: 50px;
}
.radio-inline {
    margin-right: 20px;
}
.radio-buttons {
    display: inline-block;
}
@media (max-width: 768px) {
    .radio-buttons {
        margin-top: 10px;
    }
}
.form-control {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .075);
}
.form-control:focus {
    box-shadow: inset 0 1px 1px lighten(@brand-color-lighter, 20%), 0 0 8px @brand-color-lighter;
    border-color: @brand-color-light;
}