.listing {
    position: relative;
    background-color: #eee;
    min-height: 200px;
    background-image: url('/assets/img/listing.png');
    background-size: cover;
    margin-bottom: 30px;
}
.listing:hover .listing-action {
    color: @color-accent-4;
}
.listing:hover {
    -webkit-box-shadow: 0 10px 30px 1px @generic-box-shadow-color;
    box-shadow: 0 10px 30px 1px @generic-box-shadow-color;
    cursor: pointer;
}
.listing-action {
    position: absolute;
    bottom: 20px;
    background-color: #fff;
    left: 20px;
    right: 20px;
    padding: 10px 10px 10px 20px;
    color: #777;
    font-family: @font-stack-serif;
    font-size: 12pt;
    font-style: italic;
    -webkit-box-shadow: 0 5px 5px 1px rgba(33,33,33,0.1);
    box-shadow: 0 5px 10px 1px rgba(33,33,33,0.1);
}
.listing-action-button {
    padding-right: 20px;
    float: right;
}