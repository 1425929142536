.mega-menu {
	left: 0;
	right: 0;
	top: 50px;
	z-index: 999;
	position: absolute;
	margin-bottom: 20px;
	max-height: 0;
	visibility: hidden;
	overflow: hidden;
	-webkit-transition: max-height 0.5s ease, visibility 0.8s ease-in;
    transition: max-height 0.5s ease, visibility 0.8s ease-in;
}
.mega-menu-content {
	background: #fff;
	margin-top: 50px;
	padding: 20px 50px;
}
.mega-menu h3 {
    font-size: 12pt;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 10px;
    margin-top: 0;
}
.mega-menu ul {
    padding: 0;
    font-size: 0;
}
.mega-menu ul a {
	padding: 0 !important;
}
.mega-menu-multi-column .mega-menu-item {
    width: 33.33%;
}
.mega-menu-item {
    width: 100%;
    display: inline-block;
    font-size: 11pt;
    padding: 10px;
    font-weight: 500;
    color: #B3B2B1;
}
.mega-menu-item:hover {
    background: @component-background-color;
    color: @brand-color;
    cursor: pointer;
}
.mega-menu-image {
    margin-top: 20px;
    max-height: 180px;
    overflow: hidden;
}
.mega-menu-image img {
    width: 100%;
    margin-left: 10px;
}
.mega-menu input[type=checkbox] {
	display: none;
}
.mega-menu .glyphicon {
	display: none;
}
@media (min-width: 1200px) {
	.mega-menu {
		left: -1px;
		right: -1px;
	}
	.mega-menu-content {
		border-top: 0 !important;
		border: 1px solid #ddd;
	}
	.mega-menu-multi-column {
		margin-right: 20px;
	}
}
@media (max-width: 1200px) {
	.mega-menu {
		position: relative;
		top: 0;
		text-align: left;
		padding: 0;
		max-width: 550px;
		margin-top: 0;
		display: block;
		visibility: visible;
		max-height: 5000px !important;
		margin-bottom: 0;
		-webkit-transition: none;
    	transition: none;
	}
	.mega-menu-content {
		padding: 0;
		margin-top: 0;
	}
	.mega-menu-category {
		padding: 0 15px;
	}
	.col-lg-6 .col-lg-6 .mega-menu-category {
		padding: 0 30px;
	}
	.mega-menu-multi-column .mega-menu-item {
		width: 100%;
	}
	#toggleMegaMenu:hover + .mega-menu, .mega-menu:hover {
		max-height: auto;
		visibility: hidden;
	}
	.mega-menu-image {
		display: none;
	}
	.mega-menu h3 {
		text-transform: none;
		color: @header-bar-color;
		font-size: 14px;
		padding: 15px 30px;
		margin: 0;
	}
	.mega-menu h3:hover {
		background: @component-background-color;
	    color: @brand-color;
	    cursor: pointer;
	}
	.mega-menu .glyphicon {
		display: block;
		content: '+';
		float: right;
	}
	.mega-menu li {
		padding: 10px 15px 10px 50px;
	}
	.mega-menu input[type=checkbox]:checked + ul,
	.mega-menu input[type=checkbox]:checked + .mega-menu-multi-column ul
	{
		max-height: 0px;
		overflow: hidden;
	}
	.mega-menu ul {
		-webkit-transition: max-height 0.5s ease-in-out;
    	transition: max-height 0.5s ease-in-out;
    	max-height: 2000px;
	}
}
#toggleMegaMenu:hover + .mega-menu, .mega-menu:hover {
	max-height: 500px;
	visibility: visible;
}