.product-listing {
	border: 1px solid #ddd;
	border-top: 0;
	margin-bottom: 0px;
	padding: 20px;
}
.product-listing .product img {
    box-shadow: none;
    -webkit-box-shadow: none;
}
.product-listing .product {
    display: inline-block;
    width: 135px;
}
.product-listing .product:first-child,
.product-listing .product:last-child {
    margin: 40px;
}
@media (max-width: 500px) {
	.search-filter {
		margin-bottom: 50px !important;
	}
    .product-listing {
        text-align: center;
    }
    .product-listing-top-box nav {
    	display: block;
    	margin-top: -40px;
    }
}
.product-listing-top-box,
.product-listing-bottom-box {
	position: relative;
}
.product-listing-top-box nav {
	position: absolute;
	right: 0;
	top: -20px;
}
.product-listing-bottom-box nav {
	position: absolute;
	right: 0;
	top: 0;
}
.product-listing-bottom-box nav .pagination {
	margin-top: 10px;
}
.product-listing-bottom-box {
	min-height: 60px;
	width: 100%;
	margin-bottom: 40px;
}
.nav-tabs {
	min-height: 40px;
}