.featured-content {
    position: relative;
    background-color: #eee;
    min-height: 200px;
    background-image: url('/assets/img/featured-content.png');
    background-size: cover;
    margin-bottom: 30px;
    cursor: pointer;
}
.featured-content:hover {
    -webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
    box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
}
.featured-content-action {
    position: absolute;
    top: 20px;
    background-color: #fff;
    left: 20px;
    padding: 10px 20px 10px 20px;
    font-size: 11pt;
    font-weight: 600;
}
.featured-content-action-button {
    padding-right: 20px;
    float: right;
}
.featured-content-action:hover {
    color: @color-accent-4;
}
.row .col-md-4:first-child .featured-content {
    background: #FFF1DF;
}
.row .col-md-4:nth-child(2) .featured-content {
    background: #DFF5FF;
}
.row .col-md-4:last-child .featured-content {
    background: #FFDFDF;
}
.featured-content-header h1 {
	text-align: center;
	font-size: 18pt;
	font-weight: 600;
	margin-bottom: 40px;
}