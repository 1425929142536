.behind-fixed-header {
	height: 50px;
}
.fixed-header {
	position: fixed;
    width: 100%;
    background-color: @header-bar-color;
    margin-bottom: 20px;
    z-index: 9999;
}
.fixed-header-right {
    float: right;
    padding: 0;
    font-size: 0;
}
.fixed-header-item {
    display: inline-block;
    color: #fff;
    height: 100%;
    margin: 0;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 11pt;
    text-transform: uppercase;
    border-left: 1px solid @header-bar-color-dark;
}
.fixed-header-item:last-child {
    background: @brand-color;
    border: 0;
}
.fixed-header a,
.fixed-header a:link,
.fixed-header a:visited,
.fixed-header a:hover,
.fixed-header a:active {
	color: #fff;
}
.fixed-header-item:hover,
.fixed-header-item:focus {
    background-color: @header-bar-color-light;
}
.fixed-header-item i {
    margin-right: 20px;
}
.fixed-header-item img {
    margin-top: -3px;
    margin-right: 10px;
    max-height: 11px;
}
.fixed-header .dropdown-menu {
    min-width: 200px;
    padding: 0;
    margin: 0;
    border: 1px solid @header-bar-color-dark;
    border-radius: 0;
}
.fixed-header .dropdown-menu .fixed-header-item {
    width: 100%;
}
.fixed-header .dropdown-menu .fixed-header-item:last-child {
    background-color: @header-bar-color;
}
.fixed-header .dropdown-menu .fixed-header-item:last-child:hover {
    background-color: @header-bar-color-light;
}
@media (min-width: 768px) {
    .fixed-header-item:last-child i {
        display: none;
    }
}
