.label {
    text-transform: uppercase;
}
.label-accent-1 {
    background: #fff;
    border: 2px solid @color-accent-1;
    color: @color-accent-1;
}
.label-accent-2 {
    background: #fff;
    border: 2px solid @color-accent-2;
    color: @color-accent-2;
}
.label-accent-3 {
    background: #fff;
    border: 2px solid @color-accent-3;
    color: @color-accent-3;
}
.label-accent-4 {
    background: #fff;
    border: 2px solid @color-accent-4;
    color: @color-accent-4;
}