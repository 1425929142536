// Includes
@import "includes/colors.less";
@import "includes/fonts.less";

// Components
@import "components/accordion.less";
@import "components/alert.less";
@import "components/breadcrumbmenu.less";
@import "components/button.less";
@import "components/card.less";
@import "components/cart.less";
@import "components/catalogue.less";
@import "components/checkout.less";
@import "components/consultant.less";
@import "components/contentfilter.less";
@import "components/contentlisting.less";
@import "components/featuredcontent.less";
@import "components/footer.less";
@import "components/form.less";
@import "components/freedownload.less";
@import "components/header.less";
@import "components/label.less";
@import "components/listing.less";
@import "components/megamenu.less";
@import "components/navbar.less";
@import "components/pagination.less";
@import "components/product.less";
@import "components/productlisting.less";
@import "components/productslider.less";
@import "components/progressbar.less";
@import "components/section.less";
@import "components/testimonial.less";
@import "components/typography.less";
@import "components/videoplayer.less";

* {
	font-family: @font-stack-sans-serif;
}

a:link, a:active, a:visited {
	color: @brand-color;
}
a:hover {
	color: @brand-color-lighter;
	text-decoration: none;
}
h1 {
	font-size: 24pt;
}
h2 {
	font-size: 20pt;
}
h3 {
	font-size: 16pt;
}

.login-register-spacer {
	min-height: 200px;
}
.login-form input[type="email"],
.login-form input[type="password"] {
	width: 400px;
}
.login-form .form-input {
	width: 100%;
}
@media (max-width: 992px) {
	.login-form input[type="email"],
	.login-form input[type="password"] {
		width: 300px !important;
	}
}



.sidebar-search-button {
	margin-top: 20px;
}
.sidebar-search-button button {
	width: 100%;
}
.search-box {
	margin-top: 20px;
}
.search-box input {
	margin-top: 10px;
}
.search-box h4 {
	font-weight: 600;
	margin-top: 40px;
	margin-bottom: 20px;
}
.search-heading {
	margin-top: 40px;
	margin-bottom: 40px;
}
@media (max-width: 768px) {
	.search-box {
		margin-top: 0 !important;
	}
	.search-box h4 {
		margin-top: 0 !important;
	}
	.search-heading {
		margin-top: 0px !important;
		margin-bottom: 10px;
	}
	.search-filter {
		display: inline-block;
		margin-bottom: 20px;
	}
}
.container-fluid .row .col-lg-1,
.container-fluid .row .col-lg-10,
.container-fluid .row .col-lg-11,
.container-fluid .row .col-lg-12,
.container-fluid .row .col-lg-2,
.container-fluid .row .col-lg-3,
.container-fluid .row .col-lg-4,
.container-fluid .row .col-lg-5,
.container-fluid .row .col-lg-6,
.container-fluid .row .col-lg-7,
.container-fluid .row .col-lg-8,
.container-fluid .row .col-lg-9,
.container-fluid .row .col-md-1,
.container-fluid .row .col-md-10,
.container-fluid .row .col-md-11,
.container-fluid .row .col-md-12,
.container-fluid .row .col-md-2,
.container-fluid .row .col-md-3,
.container-fluid .row .col-md-4,
.container-fluid .row .col-md-5,
.container-fluid .row .col-md-6,
.container-fluid .row .col-md-7,
.container-fluid .row .col-md-8,
.container-fluid .row .col-md-9,
.container-fluid .row .col-sm-1,
.container-fluid .row .col-sm-10,
.container-fluid .row .col-sm-11,
.container-fluid .row .col-sm-12,
.container-fluid .row .col-sm-2,
.container-fluid .row .col-sm-3,
.container-fluid .row .col-sm-4,
.container-fluid .row .col-sm-5,
.container-fluid .row .col-sm-6,
.container-fluid .row .col-sm-7,
.container-fluid .row .col-sm-8,
.container-fluid .row .col-sm-9,
.container-fluid .row .col-xs-1,
.container-fluid .row .col-xs-10,
.container-fluid .row .col-xs-11,
.container-fluid .row .col-xs-12,
.container-fluid .row .col-xs-2,
.container-fluid .row .col-xs-3,
.container-fluid .row .col-xs-4,
.container-fluid .row .col-xs-5,
.container-fluid .row .col-xs-6,
.container-fluid .row .col-xs-7,
.container-fluid .row .col-xs-8,
.container-fluid .row .col-xs-9 {
    padding-left: 0;
    padding-right: 0;
}


.container-flush .container .row .col-lg-1,
.container-flush .container .row .col-lg-10,
.container-flush .container .row .col-lg-11,
.container-flush .container .row .col-lg-12,
.container-flush .container .row .col-lg-2,
.container-flush .container .row .col-lg-3,
.container-flush .container .row .col-lg-4,
.container-flush .container .row .col-lg-5,
.container-flush .container .row .col-lg-6,
.container-flush .container .row .col-lg-7,
.container-flush .container .row .col-lg-8,
.container-flush .container .row .col-lg-9,
.container-flush .container .row .col-md-1,
.container-flush .container .row .col-md-10,
.container-flush .container .row .col-md-11,
.container-flush .container .row .col-md-12,
.container-flush .container .row .col-md-2,
.container-flush .container .row .col-md-3,
.container-flush .container .row .col-md-4,
.container-flush .container .row .col-md-5,
.container-flush .container .row .col-md-6,
.container-flush .container .row .col-md-7,
.container-flush .container .row .col-md-8,
.container-flush .container .row .col-md-9,
.container-flush .container .row .col-sm-1,
.container-flush .container .row .col-sm-10,
.container-flush .container .row .col-sm-11,
.container-flush .container .row .col-sm-12,
.container-flush .container .row .col-sm-2,
.container-flush .container .row .col-sm-3,
.container-flush .container .row .col-sm-4,
.container-flush .container .row .col-sm-5,
.container-flush .container .row .col-sm-6,
.container-flush .container .row .col-sm-7,
.container-flush .container .row .col-sm-8,
.container-flush .container .row .col-sm-9,
.container-flush .container .row .col-xs-1,
.container-flush .container .row .col-xs-10,
.container-flush .container .row .col-xs-11,
.container-flush .container .row .col-xs-12,
.container-flush .container .row .col-xs-2,
.container-flush .container .row .col-xs-3,
.container-flush .container .row .col-xs-4,
.container-flush .container .row .col-xs-5,
.container-flush .container .row .col-xs-6,
.container-flush .container .row .col-xs-7,
.container-flush .container .row .col-xs-8,
.container-flush .container .row .col-xs-9 {
    padding-left: 0;
    padding-right: 0;
}
.container-flush .container {
	padding-left: 30px;
	padding-right: 30px;
}

.blockquote-with-description {
	padding: 40px 20px;
}
.blockquote-with-description blockquote {
	margin-bottom: 0px;
	border-left: 0;
	padding-left: 0;
	font-size: 16pt;
}
.blockquote-with-description small {
	font-size: 100%;
}
.blockquote-body {
	border-left: 5px solid #ccc;
	padding-left: 20px;
	padding-bottom: 15px;
}