@card-box-shadow-color: #ddd;
.catalogues {
	position: relative;
	min-height: 400px;
}
.catalogues-action {
	margin-top: 50px;
	text-align:center;
	width: 100%;
}
.catalogue {
	position: relative;
	display: inline-block;
	min-height: 250px;
	max-width: 555px;
	margin: 20px 0;
}
.catalogue-label {
	position: absolute;
	bottom: 90px;
	left: 0;
	right: 0;
	bottom: 20px;
	background: rgba(255,255,255,0.8);
}
.catalogue-label .label {
	width: 90%;
	display: block;
	margin: 10px auto 10px auto;
	padding: 7px;
}
.catalogue .catalogue-image {
	float: left;
	position: relative;
	max-width: 200px;
	height: 100%;
}
.catalogue img {
	display: inline-block;
	margin: 0 auto 20px auto;
	-webkit-box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
	box-shadow: 0 10px 30px 0 @generic-box-shadow-color;
	width: 100%;
}
.catalogue .catalogue-details {
	margin-left: 180px;
}
.catalogue .catalogue-actions {
	margin-left: 20px;
	margin-top: 20px;
}
.catalogue .catalogue-name {
	display: block;
	font-size: 12pt;
	margin: 10px;
	font-weight: 600;
	width: 100%;
	padding-left: 10px;
}
.catalogue a.catalogue-name:link {
	color: #333;
}
.catalogue a.catalogue-name:hover {
	color: @brand-color;
}
.catalogue .catalogue-description {
	display: block;
	font-size: 12pt;
	margin: 0 20px;
	font-size: 10pt;
	color: #777;
}

@media (max-width: 500px) {
	.catalogue .catalogue-image {
		float: none;
		display: block;
		margin: 0 auto;
	}
	.catalogue .catalogue-details {
		margin: 0 0 20px 0;
	}
}