.testimonial {
    padding: 20px 80px;
    text-align: center;
    -webkit-box-shadow: 0 10px 30px 0 #ddd;
    box-shadow: 0 10px 30px 0 #ddd;
    max-width: 700px;
    margin: 20px auto 60px auto;
    background-image: url('/images/speech_mark_fade.png');
    background-position: 50px 50px;
    background-size: 70px;
    background-repeat: no-repeat;
}
.testimonial h3 {
    text-transform: uppercase;
    font-size: 9pt;
    font-weight: 700;
    color: #999;
}
.testimonial p {
    line-height: 2.5em;
    font-weight: 600;
    font-size: 12pt;
    padding: 0 20px;
}
.testimonial-details {
	max-width: 700px;
	margin: 0 auto 40px auto;
}
.testimonial-details hr {
	width: 20px;
	border-top: 2px solid #000;
}
.testimonial-details .testimonial-source-name,
.testimonial-details .testimonial-source-credentials 
{
	text-align: center;
	margin: 20px;
}
.testimonial-details .testimonial-source-name {
	font-weight: 600;
	font-size: 12pt;
}
.testimonial-details .testimonial-source-credentials {
	font-size: 9pt;
	font-weight: 700;
	text-transform: uppercase;
	color: #B3B2B1;
}
@media (max-width: 768px) {
    .testimonial {
        padding: 20px 0px;
    }
    .testimonial p {
        font-size: 100%;
        line-height: 2em;
    }
}