.heading-best-sellers,
.heading-new-releases {
    display: inline-block;
    padding-bottom: 10px;
}
.page-heading {
	text-align: center;
}
.heading-best-sellers {
    border-bottom: 3px solid @color-accent-4;
}
.heading-new-releases {
    border-bottom: 3px solid @color-accent-2;
}
.heading-accent-1 {
	color: @color-accent-1;
}
.heading-accent-2 {
	color: @color-accent-2;
}
.heading-accent-3 {
	color: @color-accent-3;
}
.heading-accent-4 {
	color: @color-accent-4;
}