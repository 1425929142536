.accordion {
	margin-top: 20px;
}
.accordion .glyphicon {
    margin-right: 10px;
}
.accordion span.glyphicon {
	float: right;
	margin-right: 0;
	margin-top: 1px;
}
.panel-collapse>.list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
.panel-collapse>.list-group .list-group-item {
    border-width: 1px 0;
}
.panel-collapse>.list-group {
    margin-bottom: 0;
}
.panel-collapse .list-group-item {
    border-radius: 0;
}
.panel-collapse .list-group .list-group {
    margin: 0;
    margin-top: 10px;
}
.panel-collapse .list-group-item li.list-group-item {
    margin: 0 -15px;
    border-top: 1px solid #ddd !important;
    border-bottom: 0;
    padding-left: 30px;
}
.panel-collapse .list-group-item li.list-group-item:last-child {
    padding-bottom: 0;
}
.panel-collapse div.list-group div.list-group {
    margin: 0;
}
.panel-collapse div.list-group .list-group a.list-group-item {
    border-top: 1px solid #ddd !important;
    border-bottom: 0;
    padding-left: 30px;
}
.panel-collapse .list-group-item li.list-group-item {
    border-top: 1px solid #DDD !important;
}
.accordion-content {
    max-height: 200px;
    overflow-y: scroll;
}
.accordion-content-section {} .accordion-content-header {
    padding: 5px 15px;
    font-weight: 600;
}
.accordion-content ul {
    padding: 0;
}
.accordion-content li {
    list-style-type: none;
    padding-left: 10px;
    border-top: 1px solid #ddd;
}
.accordion-content li:hover {
    background: #d9d9d9;
}
.accordion-content li .checkbox {
    margin: 0;
}
.accordion-content li .checkbox label {
    width: 100%;
    padding: 5px 25px;
}
.accordion .panel-heading {
    background: @component-background-color;
}
.accordion .panel>a:link {
    text-decoration: none;
}
.accordion .panel>a:hover .panel-heading {
    background: #e9e9e9;
    color: @brand-color-dark;
}
.accordion-full-width .panel-heading {
  padding: 15px 20px;
  background: #fff;
  color: @color-accent-1;
}
.accordion-full-width .accordion-content-section {
  margin-top: 20px;
}
.accordion-full-width .accordion-content-section p {
  font-size: 12pt;
  line-height: 2;
}
.accordion-full-width .accordion-content {
  padding: 10px 20px;
  max-height: none;
  overflow: hidden;
}
.accordion .panel, .accordion .panel-heading {
  border-radius: 0;
}
.accordion-container {
  background: #fff;
  padding: 10px;
  margin-bottom: 40px;
  border-top: 0;
}